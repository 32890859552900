import unionBy from 'lodash/unionBy'

import $axios from '../../scripts/api'
import moment from "moment";

export default {
  namespaced: true,
  state: () => ({
    stakePackages: [],
    _stakes: [],
    stakeTransaction: []
  }),
  actions: {
    getStakePackages({commit}) {
      return $axios.get('api/v1/stake/package').then(({data}) => {
        commit('SET_STAKE_PACKAGES', data)
      })
    },
    getStakes({commit}) {
      return $axios.get('api/v1/stake?page=0&size=100').then(({data}) => {
        commit('SET_STAKES', data?.content)
      })
    },
    getStakeTransaction({commit}) {
      return $vm.$axios.get('api/v1/stake/transactions').then(({data}) => {
        commit('SET_STAKE_TRANSACTION', data)
      })
    },
    createStake({commit}, data) {
      return $axios.post('api/v1/stake', data, {
        openModal: true,
        title: 'stake created successfully'
      }).then((resp) => {
        commit('SET_STAKES', resp.data)
      })
    },
    withdrawStake({commit}, id) {
      return $axios.post(`api/v1/stake/${id}/withdraw`, {}, {
        openModal: true,
        title: 'withdraw request successfully created'
      }).then((resp) => {
        commit('SET_STAKES', resp.data)
      })
    },
    ReStake({commit}, id) {
      return $axios.post(`api/v1/stake/${id}/compound`, {}, {
        openModal: true,
        title: 'restake request successfully'
      }).then((resp) => {
        commit('SET_STAKES', resp.data)
      })
    },
    getUserStake({commit}, id) {
      $axios.get(`/api/v1/admin/user/${id}/stake/ `).then(({data}) => {
        commit('SET_STAKES', data)
      })
    },
  },
  mutations: {
    SET_STAKE_PACKAGES(state, packages) {
      state.stakePackages = packages.map(item => ({
        ...item,
        durationInDays: Math.round(item.durationInDays / 30),
        interest: item.interest * 12
      }))
    },
    SET_STAKES(state, stakes) {
      state._stakes = stakes?.content?.map(item => ({
        ...item,
        stakeType: item.stakePackage?.code,
        apyMax: item.stakePackage?.apyMax,
        apyMin: item.stakePackage?.apyMin,
        startDate: moment(item.startDate).format('YYYY-MM-DD'),
        endDate: moment(item.endDate).format('YYYY-MM-DD'),
        statusIcon: require(`/src/assets/images/${item.status?.toLowerCase()}.png`),
      }))

    },
    SET_STAKE_TRANSACTION(state, stakes) {
      state.stakeTransaction = stakes.map(item => ({
        ...item,
        createdAt: $vm.moment(item.createdAt).format('DD/MM/YYYY'),
        status: item.transactionStatus,
        icon: require(`@/assets/images/${item.transactionStatus?.toLowerCase()}.png`)
      }))
    },
  },
  getters: {
    getStakePackages(state) {
      return state.stakePackages
    },
    stakes(state) {
      return state._stakes
    },
    getStakeTransaction(state) {
      return state.stakeTransaction
    }
  }
}
