export default {
  namespaced: true, state: {
    sidebarVisible: true, sidebarUnfoldable: false,
  },
  getters: {
    sidebarVisible(state) {
      return state.sidebarVisible
    },
    sidebarUnfoldable(state) {
      return state.sidebarUnfoldable
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    }, toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    }, updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  }, actions: {
    toggleSidebar({commit}) {
      commit('toggleSidebar')
    }
  }, modules: {},
}
