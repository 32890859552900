<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
  >
    <CSidebarBrand>
      FORTCHAIN
    </CSidebarBrand>
    <AppSidebarNav/>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import {computed} from 'vue'
import {mapActions, mapGetters, useStore} from 'vuex'
import {AppSidebarNav} from './AppSidebarNav'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  computed: {
    ...mapGetters({
      sidebarUnfoldable: 'NavBar/sidebarUnfoldable',
      sidebarVisible: 'NavBar/sidebarVisible',
    })
  }
}
</script>
