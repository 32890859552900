import {Auth} from 'aws-amplify'

export default {
  namespaced: true, state: () => ({
    user: null,
  }), actions: {
    async signUp({commit, dispatch}, {username, password, email}) {
      try {
        const {user} = await Auth.signUp({
          username, password
        });
        dispatch('modal/setModalInfo', {
          title: 'Success', description: 'User created Successfully', type: true
        }, {root: true})

      } catch (error) {
        dispatch('modal/setModalInfo', {
          title: 'Error', description: error, type: false
        }, {root: true})
      }
    },
    signIn({commit}, user) {
      return Auth.signIn(user.username, user.password).then((user) => {
        commit('SET_USER', user)
      })
    }, updateUserTokens({commit}, session) {
      commit('UPDATE_USER_SESSION', session)
    },
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    changePassword({commit}, {oldPassword, newPassword}) {
      console.log(commit)
      return Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword)
      })
    },
  }, mutations: {
    SET_USER(state, user) {
      state.user = user
    }, UPDATE_USER_SESSION(state, session) {
      state.user.signInUserSession = session
    },
  }, getters: {
    getUser(state) {
      return state.user
    },
    isAdmin(state) {
      return state.user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.includes('ADMIN')
    }
  },
}
