import $axios from '../../scripts/api'
import moment from "moment";

export default {
  namespaced: true, state: () => ({
    _funds: [],
  }), actions: {
    getFunds({commit}, id) {
      return $axios.get(`/api/v1/admin/user/${id}/fund/investment `).then(({data}) => {
        commit('SET_FUNDS', data)
      })

    },
  }, mutations: {
    SET_FUNDS(state, data) {
      state._funds = data?.map(item => ({
        ...item,
        createdAt: moment(item.createdAt).format('YYYY-MM-DD'),
        startDate: moment(item.startDate).format('YYYY-MM-DD'),
        endDate: moment(item.endDate).format('DD-MM-YYYY'),
        poolName: item.fundPool?.name,
        newAmount: item.baseAmount,
        statusIcon: require(`/src/assets/images/${item.status?.toLowerCase()}.png`),
        disable: item.status === 'WITHDRAWN'
      }))
    },
  }, getters: {
    funds(state) {
      return state._funds
    },
  }
}
