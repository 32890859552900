import $axios from '../../scripts/api'

export default {
  namespaced: true, state: () => ({
    _traders: null,
  }), actions: {
    getTraders({commit}, accountStatus = 'ENABLE') {
      $axios.get(`/api/v1/admin/trader/account?accountStatus=${accountStatus}`).then(({data}) => {
        commit('SET_TRADER', data)
      })
    },

  }, mutations: {
    SET_TRADER(state, user) {
      state._traders = user
    },
  }, getters: {
    traders(state) {
      return state._traders
    },
  },
}
