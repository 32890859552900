<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon class="mx-2" icon="cil-settings" size="lg"/>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem>
        {{ email }}
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem @click="goToProfile">
        <CIcon icon="cil-user"/>
        My Profile
      </CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem @click="logOut">
        <CIcon icon="cil-lock-locked"/>
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'AppHeaderDropdownAccnt',
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    email() {
      return this.user?.attributes?.email
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
    goToProfile() {
      this.$router.push({path: `trader/${this.user?.attributes?.sub}`})
    },
    logOut() {
      this.signOut().then(() => {
        this.$router.push('pages/login')
      })
    }
  },
}
</script>

<style lang="scss">
.nav-item {
  .dropdown-item {
    margin-right: 0;
    width: 100% !important;
    cursor: pointer;
  }
}
</style>
