export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Traders',
    to: '/traders',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Withdraw',
    to: '/withdraw',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Rewards',
    to: '/rewards',
    icon: 'cil-speedometer',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
