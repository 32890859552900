<template>
  <router-view/>
  <modals/>
</template>

<script>
import modals from '@/components/modals'

export default {
  components: {
    modals
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
