<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="toggleSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>

    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {logo} from '@/assets/brand/logo'
import {cilAccountLogout} from '@coreui/icons'
import {mapActions} from "vuex";

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    cilAccountLogout,
    logo
  },
  methods: {
    ...mapActions({signOut: 'auth/signOut',toggleSidebar:'NavBar/toggleSidebar'}),
    logOut() {
      this.signOut().then(() => {
        this.$router.push('pages/login')
      })
    }
  }
}
</script>


<style lang="scss">
.header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .dropdown-item{
    width: fit-content;
  }
}
</style>
