import Vuex from 'vuex'
import Vue from 'vue'
import auth from './modules/auth'
import NavBar from './modules/NavBar'
import users from './modules/users'
import modal from './modules/modal'
import BalanceTypes from './modules/BalanceTypes'
import stake from './modules/stake'
import funds from './modules/funds'
import traders from './modules/traders'

export default new Vuex.Store({
  modules: {
    auth,
    NavBar,
    users,
    modal,
    BalanceTypes,
    stake,
    funds,
    traders
  },
})
