<template>
  <div :class="{active:modalInfo}" v-if="modalInfo" class="modal-template">
    <div :class="modalClass" class="modal-content">
      <div @click="closeModal" class="close-container">
        <CIcon icon="cil-envelope-closed" height="36"/>
      </div>
      <span class="title">{{ modalInfo.title }}</span>
      <span class="description">{{ modalInfo.description }}</span>
      <div @click="closeModal" class="button">{{ buttonText }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      modalInfo: 'modal/getModalInfo'
    }),
    buttonText() {
      return this.modalInfo?.type ? 'Ok' : 'try again '
    },
    modalClass() {
      return this.modalInfo?.type ? 'success' : 'error'
    },
  },
  methods: {
    ...mapActions({
      setModalInfo: 'modal/setModalInfo'
    }),
    closeModal() {
      this.setModalInfo(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-template {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .6);
  pointer-events: none;
  opacity: 0;
  transform: scale(.95);
  transition: all .4s ease;
  z-index: 999999!important;
  &.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
    z-index: 2;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 30px 100px;
    width: fit-content;
    background: rgba(0, 0, 0, 0.7);
    border: 2px solid transparent;
    border-radius: 10px;
    @media only screen and (max-width: 1023px) {
      width: calc(100% - 60px);
    }

    &.success {
      border: 2px solid #06FF88;

      .title {
        color: #06FF88;
      }
    }

    &.error {
      border: 2px solid #FF0000;

      .title {
        color: #FF0000;
      }
    }

    .close-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      border-radius: 50%;
      width: 51px;
      height: 51px;
      background: #42E8E0;
      cursor: pointer;
    }

    .title {
      font-weight: normal;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
      text-transform: capitalize;
      @media only screen and (max-width: 1023px) {
        font-size: 25px;
        line-height: 35px;
      }
    }

    .description {
      color: #FFFFFF;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      text-transform: capitalize;
      margin-top: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      Width: 166px;
      Height: 60px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 58px;
      font-weight: 500;
      font-size: 20px;
      text-transform: capitalize;
      color: #FFFFFF;
      cursor: pointer;
      @media only screen and (max-width: 1023px) {
        Width: unset;
        padding: 0 20px;
      }
    }
  }
}
</style>
