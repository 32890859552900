import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $axios from './scripts/api';
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import {iconsSet as icons} from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
// Use plugin with defaults
app.use(VCalendar, {})
//register aws
import Amplify from 'aws-amplify';
import awsconfig from '@/scripts/aws-exports';

Amplify.configure(awsconfig);

app.mount('#app')
app.provide('$axios', $axios);
