import $axios from '../../scripts/api'

export default {
  namespaced: true, state: () => ({
    _users: null,
  }), actions: {
    getUsers({commit}, status = 'ENABLED') {
      $axios.get(`/api/v1/admin/users/?status=${status}`).then(({data}) => {
        commit('SET_USER', data)
      })
    },

  }, mutations: {
    SET_USER(state, user) {
      state._users = user
    },
  }, getters: {
    users(state) {
      return state._users
    },
  },
}
