import $axios from '../../scripts/api'

export default {
  namespaced: true, state: () => ({
    balanceTypes: []
  }), actions: {
    getPrices({commit}) {
      $axios.get('api/v1/wallet/deposit/token-type').then(({data}) => {
        let transformData = data?.map(item => ({
          label: item.symbol, code: item.price,
        }));
        commit('SET_BALANCE_TYPES', transformData)
      })
    }
  }, mutations: {
    SET_BALANCE_TYPES(state, data) {
      state.balanceTypes = data
    }
  }, getters: {
    getBalanceTypes(state) {
      return state.balanceTypes
    }
  }
}
